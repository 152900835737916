import './Contact.css';

function Contact() {
  return (
    <>
      <div className="contact">
        <div className="contact-image">
          <div>
            <img src="/img/contact/Bozeman.jpg" alt={"Bozeman Photo"} className={"motion-3"}/>
            <h2>45.6770° N, 111.0429° W</h2>
            <h3>Bozeman</h3>
          </div>
        </div>
        <div>
          {/**/}
          {/**/}
          <p className={"motion-1"}>
            Conveniently located...
          </p>
          <p className={"motion-2"}>
            ...off the beaten path.
          </p>
        </div>
        <div>
          <p>Email us at joe@vrausa.com</p>
        </div>
        <div className="contact-image">
          <div>
            <img src="/img/contact/Durango.jpg" alt={"Durango Photo"} className={"motion-4"}/>
            <h2>37.2753° N, 107.8801° W</h2>
            <h3>Durango</h3>
          </div>
        </div>

      </div>
    </>
  );
}

export default Contact;
