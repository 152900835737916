import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Work from "./Work";
import NavLink from "./NavLink";
import About from "./About";
import Contact from "./Contact";

function App() {
  return (
    <BrowserRouter>
      <div id="header">
        <div id="logo">
          <img src="/img/logo.png" alt="VRA USA Logo"/>
        </div>
        <div id="nav">
          <NavLink to="/">Work</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </div>
      <div id="content">
        <Routes>
          <Route path="/" element={<Work/>}/>

          <Route path="/about" element={<About/>} />

          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
