import './WorkGrid.css';
import WorkTile from "./WorkTile.js";

function WorkGrid({videos, onClickVideo, ...props}) {
  return (
    <div {...props} className="work-grid">
      {videos.map(v => (
        <WorkTile key={v.videoId} onClick={() => onClickVideo(v.videoId)} {...v} />
      ))}
    </div>
  )
}

export default WorkGrid;
