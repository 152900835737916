import './AboutProfile.css';

function AboutProfile({name, title, image, children, ...props}) {
  return (
    <div className="about-profile" {...props}>
      <div className="about-image">
        <img src={image} alt={"Photograph"}/>
      </div>
      <div className="about-details">
        <h2>{name}</h2>
        <div className="about-title">{title}</div>
        <div className="about-content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AboutProfile;
