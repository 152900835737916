import './Work.css';
import {useState} from "react";
import WorkGrid from "./WorkGrid";
import VIDEOS from "./videosList";

function Work() {

  const [playId, setPlayId] = useState();

  const handleFrameLoaded = () => {
  };

  const playVideo = (id) => {
    // window.open('https://vimeo.com/' + id, "_blank");
    setPlayId(id);
  };

  const handleClose = (evt) => {
    setPlayId(null);
    evt.preventDefault();
  }

  const player = playId && (
    <div id="player">
      <div className="close-player" onClick={handleClose} tabIndex={0}>
        &times;
      </div>
      <iframe className="work-vimeo-frame" title="vra-vimeo-frame"
              src={`https://player.vimeo.com/video/${playId}?autoplay=1&loop=0&autopause=0&muted=0&controls=1`}
              width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen
              onLoad={handleFrameLoaded}/>
    </div>
  );

  return (
    <>
      {player}
      <WorkGrid videos={VIDEOS} onClickVideo={playVideo}/>
    </>
  );
}

export default Work;
