const VIDEOS = [
  {
    "videoId": 347733500,
    "title": "Work Hard | FORD",
    "thumbnail": "/img/thumbs/WORKHARD_FORD.jpg"
  },
  {
    "videoId": 311957368,
    "title": "Community Loans of America | Texas",
    "thumbnail": "/img/thumbs/CLA_Texas.jpg"
  },
  {
    "videoId": 293203878,
    "title": "GTX Super Duty | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_GTX_SUPER_DUTY_FORD.jpg"
  },
  {
    "videoId": 292807973,
    "title": "JJ Watt | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_JJWATTS_FORD.jpg"
  },
  {
    "videoId": 292807958,
    "title": "Potholes | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_FORD_Potholes.jpg"
  },
  {
    "videoId": 292807940,
    "title": "This Should Help | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_FORD_ThisShouldHelp.jpg"
  },
  {
    "videoId": 292807917,
    "title": "Back Up Assist | FUSION",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_BACKUP_ASSIST.png"
  },
  {
    "videoId": 292807895,
    "title": "Duty Calls | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_DUTY_CALLS.jpg"
  },
  {
    "videoId": 292807878,
    "title": "ARSENAL | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_ARSENAL.jpg"
  },
  {
    "videoId": 292807858,
    "title": "Orange Beach Tourism | Gulf Shores",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_GULF_SHORES.jpg"
  },
  {
    "videoId": 292807835,
    "title": "Monster | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_MONSTER.jpg"
  },
  {
    "videoId": 292807810,
    "title": "Liftgate | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_FORD_LIFTGATE.jpg"
  },
  {
    "videoId": 292807792,
    "title": "House Fire | ServPro",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_HOUSE_FIRE_SERVPRO2.jpg"
  },
  {
    "videoId": 292807773,
    "title": "Cash Cow | Tennessee Lottery",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_CASH_COW_.jpg"
  },
  {
    "videoId": 292807545,
    "title": "40 MPG | Focus",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_FORD_FOCUS_40MPG.jpg"
  },
  {
    "videoId": 292807523,
    "title": "Testimonials | Toyota",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_TOYOTA_TESTIMONIAL.jpg"
  },
  {
    "videoId": 292807502,
    "title": "UT | Women’s Basketball",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_UT_Womens.jpg"
  },
  {
    "videoId": 292807476,
    "title": "Restaurant | ServPro",
    "thumbnail": "/img/thumbs/ServProRestaurant.png"
  },
  {
    "videoId": 292807414,
    "title": "Smashville | Predators",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_SMASHVILLE.jpg"
  },
  {
    "videoId": 292807384,
    "title": "Giant | RBC Bank",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_GIANT_RBCBANK.jpg"
  },
  {
    "videoId": 292807365,
    "title": "Marriage | REGIONS",
    "thumbnail": "/img/thumbs/MarriageRegions.png"
  },
  {
    "videoId": 292807337,
    "title": "Connected to You | Shentel",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_SHENTEL_Connected.jpg"
  },
  {
    "videoId": 292807313,
    "title": "No Borders | UT Medical Center",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_UTMC_Borders.jpg"
  },
  {
    "videoId": 292807748,
    "title": "UTMC | Types",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_UTMC_TYPES.jpg"
  },
  {
    "videoId": 292807729,
    "title": "Donna & Ray | Shentel",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_DONNA_SHENTEL.jpg"
  },
  {
    "videoId": 292807653,
    "title": "Shields Up | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_Shields_Up_FORD.jpg"
  },
  {
    "videoId": 292807593,
    "title": "What’s the Wifi? | FORD",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_WIFI_FORD.jpg"
  },
  {
    "videoId": 292807569,
    "title": "Force | U of A",
    "thumbnail": "/img/thumbs/VRA_VIMEO_THUMBNAILS_FORCE_UofA.jpg"
  },
  {
    "videoId": 292807460,
    "title": "Laundry Room | ServePro",
    "thumbnail": "/img/thumbs/ServPro_Laundry.png"
  }
];

export default VIDEOS;