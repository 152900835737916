import './About.css';
import AboutProfile from "./AboutProfile";

function About() {
  return (
    <>
      <h1 className="page-title">the crew.</h1>
      <div id="about">
        <AboutProfile name={"ALLAN VAJDA"} title={"director, cameraman"} image={"/img/about/EmployeePic_AllanUpdatedRevised.jpg"}>
          <p>As a highly acclaimed editor and then as an award-winning Director/
            Cinematographer, Allan has always used his diverse life experiences
            and his passion for adventure as inspiration and texture for
            compelling storytelling. His overarching style has been dubbed
            “stylized reality”, featuring a signature look of cinematic beauty yet
            with a special sense of honesty and true-to-life emotion.
          </p>
          <p>
            Excelling in the arena of real people talent, Allan is equally
            comfortable talking with and directing professional athletes, high-
            dollar CEO’s and salt-of-the-earth blue-collar workers, and has the
            experience and technique to bring out strong performances from
            even the most nervous or apprehensive subjects.</p>
          <p>Allan has a reputation for going all-in on every project, a knack for
            determining the essence of every story, and the experience to deliver
            each commercial to its highest potential.</p>
        </AboutProfile>

        <AboutProfile name={"JOE RAMEY"} title={"executive producer"} image={"/img/about/EmployeePic_Joe.jpg"}>
          <p>Joe Ramey has worked as a producer for both advertising agencies and
          production companies since 1985. Don’t do the math… it’s a long time.
          His commercial credits include major auto manufacturers, banks, hospitals,
          tourism, sports teams and retailers. He also has experience shooting network
          promos in LA and NY and way too many music videos while working in
            Nashville.</p>
          <p>He has extensive experience in both major markets and remote locations. As
          VRA’s Exec Producer, Joe provided logistical support, payroll and insurance
          for the First Ascent brand launch with climbs of Everest, Ama Dablam,
          Denali and others. Difficult logistics a bonus!
          Joe loves creative budgetary problem solving, putting out fires and herding
            cats as well as enjoying all the outdoor activities Montana offers.</p>
        </AboutProfile>
      </div>
    </>
  );
}

export default About;
